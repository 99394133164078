@import '../../theme.scss';

.customLink{
    cursor: pointer;
    color: $link-color;
}

.privacyPolicy h6 {
    color: #323F4D;
    font-weight: bolder;
}