@import '../../theme.scss';

.flexDiv{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonLink{
    color: $link-color;
    background: none;
    border: none;
}