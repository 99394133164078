.dropDown {
    max-height: 11rem;
    overflow: auto;
}

.z-4 {
    z-index: 999;
}

.customeStyle {
    &:hover {
        background: gainsboro;
    }
}