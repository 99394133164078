@import '../../theme.scss';
/* Place the navbar at the bottom of the page, and make it stick */

.divider{
    border-top: 2px solid $secondary-color;
}

.footer {
    overflow: hidden;
    position: static;
    bottom: 0;
    width: auto ;
    margin-top: 1.5rem;
    background-color: #ffffff;
}

.footer h5{
    font-weight: 600;
}

.logo{
    width: 210px;
    margin-bottom: 0.5rem;
}

.containerClass {
    // border-top: 0.5px solid lightgrey;
}