@import '../../../../../theme.scss';
button {
  cursor: pointer;
  background: transparent;
  border: 0;
}
 
button:focus {
  outline: none;
}
 
button img {
  width: 20px;
  height: 20px;
}
 
input[type=file] {
  display: none;
}

.stepTwoPage {
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
}

.secondary-text-button {
  color: $secondary-color;
	background: #fff;
}

.warning-text-button {
  color: #ffc107;
  background: #fff;
}

.modal-body-height {
  max-height: 70vh;
  overflow: auto;
}

.wide-bordered-button-secondary {
  background-color: white;
  color: $secondary-color;
  border: 1px solid $secondary-color;
  min-width: 12.7vw
}

.line-label {
  width: 100%; 
  text-align: center; 
  border-bottom: 0.1px solid #dee2e6; 
  line-height: 0.1em;
  margin: 5px 0 7px;
}

.line-label span {
  background:#fff; 
  padding:0 10px; 
}

.list-Style-Disc {
  list-style-type: disc !important;
}

.dragAndDropOverlay {
  width: 100%;
	min-height: 10rem;
	background-color: rgba(0, 0, 0, 0.5);
}

.show-more-button {
  color: $secondary-color;
  background-color: #fff;
}

.wide-bordered-button-secondary:hover {
  background-color: white !important;
  color: $secondary-color !important;
  border: 1px solid $secondary-color;
}

.wide-bordered-button-secondary:active {
  background-color: white !important;
  color: $secondary-color !important;
  border: 1px solid $secondary-color;
}

.wide-bordered-button-secondary:focus {
  background-color: white !important;
  color: $secondary-color !important;
  border: 1px solid $secondary-color;
  box-shadow: 0 0 0 .2rem #d66dee
}
.dropdown-toggle.btn.btn-primary{
  font-weight: 600;
}

.show>.btn-primary.dropdown-toggle {
  color: $secondary-color;
  background-color: white;
  border: 1px solid $secondary-color; 
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d66dee  
}

.dropdown-menu {
  z-index: 1040;
}

.dropdown-item:active {
  background-color: $secondary-color;
}