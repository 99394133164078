@import '../../theme.scss';

html {
    scroll-behavior: smooth;
}

.lightDark{
    color: black;
}

nav.customTabset{
    margin: 0px;
    border-bottom: 1px solid $light-dark;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

nav.customTabset .customTab{
    color: $light-dark;
    white-space: nowrap;
}

nav.customTabset .customActiveTab{
    color: $secondary-color !important;
    font-weight: 600;
    border-color: #ffffff #ffffff $secondary-color #ffffff !important;
    border-width: 3px;
    white-space: nowrap;
}

nav.customTab .customTab{
    color: $light-dark;
}

nav.customTab .customActiveTab{
    color: $secondary-color !important;
    font-weight: 600;
    border-color: #ffffff #ffffff $secondary-color #ffffff !important;
    border-width: 3px;
}

.customLink{
    color: $primary-color;
}

.customLink:hover{
    color: $secondary-color;
}

textarea.customFormField{
    resize: none;
    border-radius: 4px;
}

.formErrors{
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
    margin-bottom: 0.5rem;
}

.formFieldHeight{
    min-height: 100px;
}

.linkButton{
    color: $secondary-color;
    background-color: #ffffff;
}

.width15{
    width: 15%;
}

.infoLabel{
    color: #4a4a4a;
    font-weight: 400;
    font-size: 14px;
}

.customLabel{
    color: $light-dark;
    font-weight: 700;
    margin-left: 0.5rem;
}

.goToInput {
    width: 80px !important;
}

.redBull {
	color: red;
	font-size: 2rem;
}

.orangeBull {
	color: orange;
	font-size: 2rem;
}