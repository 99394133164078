@import '../../theme.scss';

.lightDark{
    color: $light-dark;
}

.customLink{
    color: $secondary-color;
    cursor: pointer;
    &:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}


.secondaryColor {
    border-color: $secondary-color;
    color: $secondary-color;
    &:hover {
        color: $secondary-color;
    }
}
