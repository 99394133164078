.flexContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fff;
}

.logo{
    width: auto;
    height: 120px;
}