@import "../../../../../theme.scss";
.tHead {
    font-size: 13px;
    font-weight: 700;
    color: #7D7D7D;
}

.bodyStyle {
	height: 55vh;
	overflow-y: auto;
}

.modalBody {
    max-height: 80vh;
    overflow-y: auto;
}

.bodyToStyle {
    height: 65vh;
	overflow-y: auto;
}

.invalidDiv {
    height: 60vh;
}

.commonInvalidReferenceStyle {
    height: 30vh;
	overflow-y: auto;
}

.errorAccordian {
    overflow-y: auto;
    display: block;
    max-height: 55vh;
    min-height: 55vh;
    height: auto;
}

// .LoaderDiv {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     align-items: center;
//     height: 40vh;
// }

.figureCaptionList {
    max-height: 50vh;
    overflow: auto;
}

.secondary-text-button {
    color: $secondary-color;
    background: #fff;
    border: 1px solid $secondary-color !important;
}
.removeFigureBtn {
    width: auto !important;
}
.warning-text-button {
    color: #ffc107;
    background: #fff;
}

nav.customTabset{
    margin: 0px;
    border-bottom: 1px solid lightgrey;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

nav.customTabset .customTab{
    color: $light-dark;
    white-space: wrap;
}

nav.customTabset .customActiveTab{
    color: $secondary-color !important;
    font-weight: 600;
    border-color: #ffffff #ffffff $secondary-color #ffffff !important;
    border-width: 3px;
    white-space: wrap;
}

.iconColor {
    color: $secondary-color
}

.activeSection {
    background-color: $secondary-color;
    color: white;
}

.secondaryTextButton {
    color: $secondary-color;
    background: #fff;
    border: 1px solid $secondary-color !important;
}
