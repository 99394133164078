@import '../../../../../theme.scss';

.linkText {
    color: $secondary-color;
    &:hover {
        color: $secondary-color;
    }
}

.btn {
    width: 40%;
}

.jornalTitle {
    font-weight: 500;
}

.actionBtnSection{
    @media screen and (max-width: 527px) {
        button {
            width: 100%;
            display: block;
        }
        
    }
}