@import '../../theme.scss';

nav.customTabset{
    margin: 0px;
    border-bottom: 1px solid $light-dark;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

nav.customTabset .customTab{
    color: $light-dark;
    white-space: nowrap;
}

nav.customTabset .customActiveTab{
    color: $secondary-color !important;
    font-weight: 600;
    border-color: #ffffff #ffffff $secondary-color #ffffff !important;
    border-width: 3px;
    white-space: nowrap;
}

.customInput input{
    border-radius: 0px;
}

.customInput input:focus{
    box-shadow: none;
    border-color: inherit;
}

.formErrors{
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
    margin-bottom: 0.5rem;
}

.inputNumberStyle {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}

.secondaryColor {
    color: $secondary-color;
}
