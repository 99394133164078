@import url("../../../../../theme.scss");
.keywordBlock {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.keywordSpan {
    background: #D8F5F5;
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 16px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.btn {
    background: transparent;
    color: #FF0000;
}

.dropdown {
    height: 10rem;
    font-size: 16px;
    width: 100%;
}

input.selectKeyword {
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #BCBCBC;
    border-radius: 6px;
}

@media screen and (max-width: 1044px) {
.mainSection .searchBar.col-lg-6, .actionBtn.col-lg-6 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

.mainSection {
    .searchBar {             
            @media screen and (max-width: 928px) {
                max-width: 100%;
                flex: 0 0 100%;
            } 
    }
    .actionBtn {             
        @media screen and (max-width: 928px) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.labelText {
    font-weight: 400;
    font-size: 14px;
}

.borderlessButton {
    color: #7C2F8D;
    background: #fff;
}
