@import "../../../theme.scss";
.btnStyle {
    width: 15%;
    margin-top: 1rem;
}

.btnReset {
    background-color: transparent;
    color: $secondary-color;
}
.rti--input{
    width: 100% !important;
}

.rti--tag button{
    color: #323F4D !important;
}
nav.navTabs {
    font-size: 1rem;
    // flex-wrap: nowrap;
    .navLink{
        border-radius: 0 !important;
        font-weight: 400;
        color: #323F4D;
        font-size: large;
        &.active {
            color: $secondary-color !important;
            border-bottom: 3px solid $secondary-color;
            background-color: transparent !important;
            font-weight: 600;
            font-size: large;
            border-top: transparent;
            border-left: transparent;
            border-right: transparent;
        }
    }
}

.btnPosition {
    position: absolute;
    top: 10px;
    right: 1rem;
    border-radius: 4px;
    width: fit-content;
    font-size: 1rem;
    padding: 0.5rem;
    @media (max-width:1024px) {
        position: unset;
        align-self: flex-end;
    }
}

.sortStyle {
    color: #7C2F8D;
    outline: none;
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 600;
}

.searchArea {

    @media screen and (max-width: 572px) {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 1rem;

        button {
            width: 100%;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .btnReset {
            margin-left: 0;
        }
    }   
}

.linkText {
    color: $secondary-color;
    font-weight: 500;
    text-decoration: underline;
    font-size: 16px;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.didYouMean {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
}

@media screen and (max-width: 850px) {
    .tabSection.col-md-9, .filterSection.col-md-3, .resultDetailsSection.col-md-9 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media screen and (max-width: 630px) {
    .btnPosition {
       position: unset;
       margin: 0 0 8px 0;
    }
}

@media screen and (max-width: 572px) {
    nav.navTabs {
        font-size: 0.9rem;
    }

    .navTabs .navLink {
        padding: 0.5rem;
    }
}

