@import "../../theme.scss";
// .centeredWidth{
//     width: 90vw;
//     margin: auto;
// }

.copyRightText {
    background-color: $secondary-color;
    color: white;
    font-weight: 700;
    padding: 0.75rem;
}