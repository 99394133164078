@import '../../theme.scss';

.customLink{
    color: $primary-color;
   
}


.secondaryLink{
    color: $secondary-color;
    &:hover {
        color: $secondary-color;
    }
}

.secondaryColor {
    border-color: $secondary-color;
    color: $secondary-color;
    &:hover {
        color: $secondary-color;
    }
}