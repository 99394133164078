@import '../../../theme.scss';

.message {
    text-align: justify;
}

.customButton{
    color: $secondary-color;
    background-color: transparent;
    border: 1px solid $secondary-color;
    border-radius: .25rem;
}