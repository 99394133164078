@import '../../theme.scss';

.customLink {
    color: $light-dark;
    background: none;
}

.font12{
    font-size: 12px;
}

.font18{
    font-size: 18px;
}

.customButton{
    display: inline-block;
    color: $secondary-color;
    background-color: transparent;
    border: 1px solid $secondary-color;
    border-radius: .25rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
}

.customButton:hover{
    color: $secondary-color;
    text-decoration: none;
}

.actionDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.nonDotList{
    padding: 0px;
    list-style-type: none;
}

.verticalDivider{
    height: 100%;
    border-right: 1px solid rgba(0,0,0,.1);
}

@media only screen and (max-width: 600px) {
    .actionDiv {
        flex-direction: column;
        align-items: flex-start;
    }

    .verticalDivider{
        height: 10px;
        border-top: 1px solid rgba(0,0,0,.1);
    }

}

.loader {
    width: 15rem;
}

.message {
    text-align: justify;
}

.scopeData {
    div {
        margin-bottom: 10px;
    }
}