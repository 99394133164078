@import '../../theme.scss';

.primaryText {
    color: $secondary-color;
}

.scopeData {
    div {
        margin-bottom: 10px;
    }
}

.selectedCardBorder {
    border-color: $secondary-color !important;
    border-width: 2px !important;
}