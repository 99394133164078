@import '../../theme.scss';

.customInput input{
    border-radius: 0px;
}

.customInput input:focus{
    box-shadow: none;
    border-color: inherit;
}

.link{
    color: $secondary-color;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}

.link:hover{
    color: $link-color !important;
}

.registerForm{
    width: auto;
}

.validators{
    display: block;
    background-color: #FFBABA;
    color: #D8000C;
    font-size: 12px;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid;
}

.formErrors{
    font-size: 12px;
    color: red;
    display: block;
    height: 20px;
    margin-bottom: 0.5rem;
}

.logoLabel{
    color: #4a4a4a;
    font-weight: 400;
    text-align: center;
}

.inputNumberStyle {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
}
