@import "../../../../theme.scss";

.dateStyle {
    &::before {
        content: "\2022";
        margin: 0rem .5rem;
        font-size: 1.2rem;
    }
}

.itemLink {
    color: $secondary-color;    
    font-size: 18px;
    font-weight: 600;
    &:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}

.itemLinkDisabled {  
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    &:hover {
        color: $secondary-color;
        text-decoration: underline;
    }
}

.btn-outline {
    background-color: transparent;
    color: $secondary-color;
    border: 1px solid $secondary-color;
}

.authorText {
    color: $light-dark;
    font-size: 12px;
    font-weight: 700;
}

.gap1 {
    column-gap: 0.5rem;
}

.manuscriptStatusStyle { 
    .lines {
        content: "";
        width: 4%;
        border-bottom:1px dotted $light-dark;
        margin: 0.6rem -0.4rem 0.6rem -0.3rem;
    }

    input{
        &:hover {
            cursor: pointer;
        }
    }

    input[type="radio" i] {
        appearance: inherit;
      }
      
      input[type="radio"] {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        padding: 2px;
        margin-right: 3px;
        background-clip: content-box;
        border: 2px solid $light-dark;
        background-color: transparent;
        border-radius: 50%;
      }
      
      input[type="radio"]:checked {
        background-color: blue;
        border: 2px solid blue;
      }

      input.rejected[type="radio"]:checked {
        background-color: red;
        border: 2px solid red;
      }
    
}

.textLight {
    color: $light-dark;
}

.textDark {
    color:#323F4D
}

.actionButtons {
    .btnDanger {
        color: #FF0000;
        background-color: transparent;
        border-color: #FF0000;
        &:hover{
            color: #FF0000;
            background-color: transparent;
            border-color: #FF0000;
        }
    }

    @media screen and (max-width: 767px) {
        margin-top: 1rem;
    }
}


.w-18 {
    width: 18rem;
}

.dropdown-toggle::after {
    display:none !important;
}

// .btnToggle {
//     background-color: white !important;
//     border: white !important;
//     color: black !important;

//     &:hover {
//         background-color: white !important;
//         border: white !important;
//         color: black !important;
//     }
// }

@media screen and (max-width: 577px) {
    .manuscriptStatusStyle form {
        flex-direction: column; 
    }

    .manuscriptStatusStyle .lines {
        content: none;
        border: none;
    }
}


.h-2 {
    height: 0.85rem !important;
}

.progressStatus {
    font-size: 14px;
}
