.buttonPrimary{
    color: #7C2F8D;
    background: #fff;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #7C2F8D;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    &:disabled{
     opacity: 0.7;
    }
    
}
.redButton{
    @extend .buttonPrimary;
    color: #FF0000;
    border: 1px solid #FF0000;
    &:disabled{
        opacity: 0.7;
       }
}
