@import '../../../../../theme.scss';
.action-div {
    // min-height: 45vh;
    height: auto;

    button {
        width: 10rem;
    }
}

.action-div .stepSixActionBtn button {
    width: 100%;
    margin-bottom: 1rem;
}

.skip-Button {
    color: $secondary-color;
	background: #fff;
    border: 1px solid $secondary-color !important;;
}

.skip-Button:hover {
    color: $secondary-color;
	background: #fff;
    border: 1px solid $secondary-color !important;;
}

.no-underline {
    text-decoration: none !important;

    :hover {
        text-decoration: none !important;
    }
}

.mh-30 {
    height: 50vh;
    overflow: auto;
}

.width-auto {
    width: auto !important;
}

.primary-btn {
    background-color: $secondary-color;
    color: white;
}

.primary-btn:hover {
    color: white !important;
    background-color: #552061 !important;
}