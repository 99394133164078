@import '../../theme.scss';

.customTitle{
    font-size: 12px;
    font-weight: 600;
    color: $light-dark;
}

.labelColor{
    color: #cdcdcd;
}

.customDialog{
    margin: 0rem !important;
    height: 100vh;
}

.customModalHeader{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: none;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.customModalContent{
    height: 100%;
    border: none !important;
    border-radius: 0rem !important;
}

.floatButton{
    position: fixed;
    top: 5%;
    right: 0%;
    margin-right: 0.5rem;
    background-color: $secondary-color;
    border-radius: 50%;
    z-index: 1000;
}

.customButton{
    width: 100%;
    color: $secondary-color;
    background: transparent;
    border: 1px solid $secondary-color;
}

.windowFilter{
    display: block;
    @media (max-width:576px) {
        display: none;
    }
}

.customLink{
    color: $secondary-color;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background: none;
}

.mobileFilter{
    display: none;
    z-index: 1000;
    @media (max-width:576px) {
        display: block;
    }
}

.filterSection {
    position: sticky;
    top: 0;
    z-index: 1000;
}
