@import '../../../../../theme.scss';


.toastMessage {
	position: fixed;
	top: 20px;
	width: 300px;
	text-align: left;
	background: $secondary-color !important ;
	color: #fff;
	left: 40%;
	font-weight: 500;
}

.redBull {
	color: red;
	font-size: 2.5rem;
}

.orangeBull {
	color: orange;
	font-size: 2.5rem;
	margin-bottom: 0.2rem;
}

.noteButton {
	color: $secondary-color;
	background-color: white;
	padding: 0px;
}

.labelButtonLink {
	color: $secondary-color;
}

.modalHeaderSubtext {
	font-size: small;
}