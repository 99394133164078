
.editingBtnStyle{
    font-weight: 500;
    color: #7C2F8D;
    border: #7C2F8D 1px solid;
}

.editingBtnStyle:hover {
    color: white;
    background-color: #7C2F8D;
}